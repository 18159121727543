import React, {useState} from 'react';
import {ScreenBottomDialog} from "../../../ScreenBottomDialog";
import {DialogContent, Paper, Tab, Typography} from "@material-ui/core";
import {Button} from "../../../index";
import {withStyles} from "@mui/styles";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import {useIntl} from "react-intl";
import {ImportContent} from "../ImportContent";
import useParticipantsHelper from "../../../../hooks/Participants/UseParticipantsHelper";
import usePointHelper from "../../../../hooks/Points/UsePointHelper";
import {DialogIndicator} from "../../../DialogIndicator";
import {neutralColors} from "../../../../themes";
import {createTheme} from "@material-ui/core/styles";
import useImportLogFile from "../../../../hooks/UseImportLogFile";
import {ImportLogsTypes} from "../../../../enums/importLogsTypes";
import {useParams} from "react-router-dom";
import {useAuth} from "../../../../../auth";
import {CloseCircle} from "iconsax-react";

const styles = {
    dialogTitle: {
        display: 'flex !important',
        flexDirection: 'column !important',
        padding: '1rem !important',
        width: '100%',
    },
    closeTitleDialog: {
        display: 'flex !important',
        width: '100%',
        flexDirection: 'row-reverse !important',
        padding: '1rem !important',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        gap: '1rem',
        backgroundColor: neutralColors.neutralBg,
        [createTheme().breakpoints.up('md')]: {
            padding: '0rem 2rem 2rem 2rem !important',
        },
    },
    dialogPaperContent: {
        borderRadius: '1rem',
        marginRight: 'auto',
        marginLeft: 'auto',
        '&.MuiPaper-root': {
            boxShadow: 'none'
        },
        [createTheme().breakpoints.up('md')]: {
            padding: '1rem',
            width: '75%',
        },
    }
}

const ImportDialogComponent = ({ open, setOpen, defaultTab, onFileCreated, ...props }) => {
    const intl = useIntl();
    const { contract } = useParams();
    const { hierarchyNodeUser } = useAuth();

    const [importType, setImportType] = useState(null);

    const { createImportLog } = useImportLogFile({ onFileCreated: () => {
            onFileCreated(importType)
            onClose()
        }, overrideCreateImportKey: 'createImport', overrideExportLogKey: 'exportLog' });
    const { importRequirements: participantsImportRequirements, onDownloadImportPattern: participantOnDownloadImportPattern } = useParticipantsHelper();
    const { importRequirements: pointsImportRequirements, onDownloadImportPattern: pointsOnDownloadImportPattern } = usePointHelper();

    const POINTS_TAB = { label: intl.formatMessage({id: 'spider.points.title'}), index: '1' }
    const PARTICIPANTS_TAB = { label: intl.formatMessage({id: 'spider.participants.title'}), index: '2' }

    const getDefaultTab = () => {
      return defaultTab ? defaultTab : PARTICIPANTS_TAB.index
    }

    const [tabValue, setTabValue] = useState(getDefaultTab());

    const handleChange = (event, tab) => {
        setTabValue(tab);
    };

    const onParticipantValidate = (files) => {
        const importTypeLocal = ImportLogsTypes.USERS
        setImportType(importTypeLocal)
        createImportLog(files[0], importTypeLocal, contract, hierarchyNodeUser.uuid)
    }

    const onPointValidate = (files) => {
        const importTypeLocal = ImportLogsTypes.POINTS
        setImportType(importTypeLocal)
        createImportLog(files[0], importTypeLocal, contract, hierarchyNodeUser.uuid)
    }

    const onClose = () => {
        setOpen(false)
        setTabValue(getDefaultTab())
    };

    return (
        <ScreenBottomDialog open={open} onClose={onClose} direction={'right'}>
            <DialogContent className={props.classes.dialogContent}>
                <div className={props.classes.dialogTitle}>
                    <DialogIndicator/>
                    <div className={props.classes.closeTitleDialog}>
                        <Button variant={'contained'} color={'primary'} size={'small'} className={'icon light'} onClick={onClose}>
                            <CloseCircle/>
                        </Button>
                    </div>
                    <div>
                        <Typography variant={'h1'} component={'h1'} className={'title underline-center'}>
                            {intl.formatMessage({id: 'spider.importDialog.title'})}
                        </Typography>
                    </div>
                </div>

                <Paper className={props.classes.dialogPaperContent}>
                    <TabContext value={tabValue}>
                        <TabList onChange={handleChange} TabIndicatorProps={{children: <div/>}} className={'switch'}>
                            <Tab label={POINTS_TAB.label} value={POINTS_TAB.index} disableFocusRipple/>
                            <Tab label={PARTICIPANTS_TAB.label} value={PARTICIPANTS_TAB.index} disableFocusRipple/>
                        </TabList>
                        <TabPanel value={POINTS_TAB.index}>
                            <ImportContent
                                key={'imports'}
                                title={intl.formatMessage({id: 'spider.points.helpImport.header.title'})}
                                subtitle={intl.formatMessage({id: 'spider.points.helpImport.header.subtitle'})}
                                downloadPattern={pointsOnDownloadImportPattern} requirementObject={pointsImportRequirements}
                                validateImport={onPointValidate}
                            />
                        </TabPanel>
                        <TabPanel value={PARTICIPANTS_TAB.index}>
                            <ImportContent
                                key={'participants'}
                                title={intl.formatMessage({id: 'spider.participants.helpImport.header.title'})}
                                subtitle={intl.formatMessage({id: 'spider.participants.helpImport.header.subtitle'})}
                                downloadPattern={participantOnDownloadImportPattern} requirementObject={participantsImportRequirements}
                                validateImport={onParticipantValidate}
                            />
                        </TabPanel>
                    </TabContext>
                </Paper>
            </DialogContent>
        </ScreenBottomDialog>
    )
}

export default withStyles(styles)(ImportDialogComponent);
