import React, {useState} from 'react'
import {withStyles} from "@mui/styles";
import {Building, ProfileCircle} from "iconsax-react";
import {systemColors} from "../../../../../../../../../../../../themes";
import {FormControl, FormControlLabel, Switch, Typography} from "@material-ui/core";
import {useIntl} from "react-intl";
import useDirtyPristine from "../../../../../../../../../../../../hooks/UseDirtyPristine";
import Formsy from "formsy-react";
import Button from "../../../../../../../../../../../../components/Button";
import {createTheme} from "@material-ui/core/styles";
import useHandleToast from "../../../../../../../../../../../../hooks/UseHandleToast";
import {
    updateRewardsAllocationsKeyClear, updateRewardsAllocationsKeyStart
} from "../../../../../../../../../../../../features/rewardAllocations/updateRewardAllocations/slices";
import {useDispatch, useSelector} from "react-redux";
import {ConfigurationImage} from "../ConfigurationImage";
import {
    RewardAllocationConfigurationImageCodes
} from "../../../../../../../../../../../../enums/rewardAllocationConfigurationImageCodes";
import {ConfigurationField} from "../ConfigurationField";

const styles = {
    configurationLayout: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    configurationTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    configurationTitleLabel: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem'
    },
    configurationContentInputs: {
        display: 'grid',
        gap: '1rem',
        gridTemplateColumns: 'repeat(1, 1fr)',
        [createTheme().breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [createTheme().breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
    },
    configurationContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
    },
    configurationContentLayout: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem'
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
        justifyContent: 'center',
        alignItems: 'center'
    }
}

const RewardAllocationsConfiguration = ({ rewardLabel, rewardAllocationId, configurations, activeConfigurations = [], physicPerson = false, ...props }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const { dirtyChange, resetPristinePage } = useDirtyPristine();

    const updateRewardsAllocationsKey = useSelector(state => state.updateRewardsAllocations);

    const [enableConfiguration, setEnableConfiguration] = useState(configurations.length > 0);
    const [configurationValues, setConfigurationValues] = useState(activeConfigurations)
    const [listener, setListener] = useState(false)

    const clearListener = () => {
        setListener(false)
    }

    useHandleToast(updateRewardsAllocationsKey, dispatch, updateRewardsAllocationsKeyClear,
        'spider.hierarchy_node.platform_customization.parameters.rewards.update.success',
        'spider.hierarchy_node.platform_customization.parameters.rewards.update.error', listener, clearListener)

    const onConfigurationChange = (configuration) => {
        dirtyChange()
        const configurationValuesChanged = JSON.parse(JSON.stringify(configurationValues))
        const configurationIndex = configurationValues.findIndex((configurationValue) => configurationValue.code === configuration.code)
        if (configurationIndex !== -1) {
            configurationValuesChanged.splice(configurationIndex, 1, configuration)
        } else {
            configurationValuesChanged.push(configuration)
        }
        setConfigurationValues(configurationValuesChanged)
    }

    const renderFieldConfiguration = () => {
        return configurations.map((configuration, index) => {
            const configurationValue = activeConfigurations.find((activeConfiguration) => configuration.code === activeConfiguration.code)
            return (<ConfigurationField key={index} configuration={configuration} configurationDefaultValue={configurationValue ? configurationValue.value : ''} onConfigurationChange={onConfigurationChange}/>)
        })
    }

    const checkConfiguration = (event) => {
        dirtyChange()
        setEnableConfiguration(event.target.checked)
    }

    const saveConfiguration = () => {
        setListener(true)
        resetPristinePage()
        const rewardAllocation = {}
        const configs = enableConfiguration ? configurationValues : null
        if (physicPerson) {
            rewardAllocation.individuals_config = configs
        } else {
            rewardAllocation.legal_entities_config = configs
        }
        dispatch(updateRewardsAllocationsKeyStart({
            rewardAllocationId,
            rewardAllocation
        }))
    }

    return (
        <Formsy name={'parameter-contract-details'} onValidSubmit={saveConfiguration}
                className={props.classes.configurationLayout}>
            <div className={props.classes.configurationContentLayout}>
                <div className={props.classes.configurationTitle}>
                    <div className={props.classes.configurationTitleLabel}>
                        {physicPerson ? <ProfileCircle color={systemColors.errorRegular}/> :
                            <Building color={systemColors.errorRegular}/>}

                        <Typography variant={"subtitle1"} style={{fontWeight: 'bold'}}>
                            {physicPerson ? intl.formatMessage({id: 'spider.hierarchy_node.platform_customization.parameters.rewards.physicPerson'})
                                : intl.formatMessage({id: 'spider.hierarchy_node.platform_customization.parameters.rewards.moralePerson'})}
                        </Typography>
                    </div>

                    <FormControl style={{display: 'flex', flexDirection: 'row'}}>
                        <FormControlLabel
                            checked={enableConfiguration}
                            control={<Switch color={'primary'}/>}
                            labelPlacement="start"
                            label={enableConfiguration ? intl.formatMessage({id: 'spider.hierarchy_node.platform_customization.parameters.rewards.enabledPerson'})
                                : intl.formatMessage({id: 'spider.hierarchy_node.platform_customization.parameters.rewards.disabledPerson'})}
                            style={{margin: 0}}
                            onChange={checkConfiguration}
                        />
                    </FormControl>
                </div>

                {enableConfiguration &&
                    <div className={props.classes.configurationContent}>
                        <div className={props.classes.configurationContentInputs}>
                            {renderFieldConfiguration()}
                        </div>

                        <ConfigurationImage rewardLabel={rewardLabel} rewardAllocationId={rewardAllocationId} code={physicPerson ? RewardAllocationConfigurationImageCodes.INDIVIDUALS_REWARD_ALLOCATION_IMAGE : RewardAllocationConfigurationImageCodes.LEGAL_ENTITIES_REWARD_ALLOCATION_IMAGE}/>
                    </div>
                }
            </div>

            {enableConfiguration &&
                <div className={props.classes.actions}>
                    <Button color={'primary'} variant={'outlined'} size={'small'} type={'submit'}>
                        {intl.formatMessage({id: 'spider.hierarchy_node.platform_customization.parameters.rewards.saveButtonLabel'})}
                    </Button>
                </div>
            }
        </Formsy>
    )
}

export default withStyles(styles)(RewardAllocationsConfiguration)
