import authReducers from './auth/reducers';
import baseReducers from './base/reducers';
import configsReducers from './config/reducers';
import teamGroupReducers from './teamGroup/reducers';
import systemImageReducers from './systemFiles/reducers';
import settingsReducers from './settings/reducers';
import mainReducers from './main/reducers'
import importLogsReducers from './importLogs/reducers'
import contractDetailsReducers from './contractDetails/reducers'
import rewardsReducers from './rewards/reducers'
import rewardAllocationsReducers from './rewardAllocations/reducers'

export default {
    ...authReducers,
    ...baseReducers,
    ...configsReducers,
    ...teamGroupReducers,
    ...systemImageReducers,
    ...settingsReducers,
    ...mainReducers,
    ...importLogsReducers,
    ...contractDetailsReducers,
    ...rewardsReducers,
    ...rewardAllocationsReducers,
}
